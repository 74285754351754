<template>
  <div
    class="uk-flex uk-flex-center uk-flex-middle uk-background-cover"
    uk-height-viewport
    :data-src="backgrounds[Math.floor(Math.random() * backgrounds.length)]"
    uk-img
  >
    <div>
      <div
        class="
          uk-card uk-card-secondary uk-border-rounded uk-width-medium uk-padding
        "
      >
        <div class="uk-text-center">
          <img :src="api + '/storefronts/' + storefrontId + '/logo'" alt />
        </div>

        <div class="uk-text-center uk-margin-top">Reestablecer contraseña</div>

        <ValidationObserver v-slot="{ invalid }">
          <div class="uk-margin-top">Correo</div>
          <ValidationProvider name="email" rules="required|email">
            <input
              class="uk-input uk-text-center uk-border-rounded"
              type="text"
              v-model="model.email"
              placeholder="correo"
              name="email"
              disabled
            />
          </ValidationProvider>
          <div class="uk-margin-top">Contraseña</div>
          <ValidationProvider
            name="Contraseña"
            rules="required|confirmed:password_confirmation"
            v-slot="{ errors }"
          >
            <PasswordInput v-model="model.password" name="Contraseña" />
            <span>{{ errors[0] }}</span>
          </ValidationProvider>
          <div class="uk-margin-top">Confirmación deContraseña</div>
          <ValidationProvider
            name="Confirmación de contraseña"
            rules="required"
            vid="password_confirmation"
            v-slot="{ errors }"
          >
            <PasswordInput
              v-model="model.password_confirmation"
              name="Confirmación de Contraseña"
            />
            <span>{{ errors[0] }}</span>
          </ValidationProvider>

          <div class="uk-text-center uk-margin-top">
            <button
              class="uk-button uk-button-primary uk-border-rounded"
              @click="resetPassword"
              :disabled="invalid"
            >
              Reestablecer
            </button>
          </div>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import UIkit from "uikit";
import PasswordInput from "@/views/Utils/PasswordInput";

export default {
  name: "PasswordReset",

  components: {
    PasswordInput,
  },

  data() {
    return {
      model: {
        email: this.$route.query.email,
        password: null,
        password_confirmation: null,
        token: this.$route.query.token,
      },
      backgrounds: [],
    };
  },

  methods: {
    resetPassword() {
      this.axios.post("/password-reset", this.model).then(() => {
        UIkit.notification({
          message:
            "Se ha reestablecido tu contraseña exitosamente. Ingresa con tus nuevas credenciales.",
          status: "success",
          pos: "bottom-left",
        });

        this.$router.push("/login");
      });
    },
  },
};
</script>
